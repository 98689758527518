import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useFetcher } from "react-router";
import { v1 as uuidv1 } from "uuid";
import Alert from "~/components/alert";
import Button from "~/components/button";
import ButtonGroup from "~/components/button-group";
import FormattedDate from "~/components/formatted-date";
import Heading from "~/components/heading";
import {
  IconAdd,
  IconCheck,
  IconExclamation,
  IconList,
  IconTrash
} from "~/components/icons";
import LinkTasks from "~/components/kanban/link-tasks";
import Metrics from "~/components/kanban/metrics";
import Link from "~/components/link";
import type { ModalProps } from "~/components/modal";
import Modal from "~/components/modal";
import { RemixForm, fetcherFailed } from "~/components/remix-form";
import { Buttons } from "~/components/remix-form/buttons";
import { Errors } from "~/components/remix-form/errors";
import { Input } from "~/components/remix-form/input";
import InlineTaskFieldsNext from "~/components/tasks/inline-fields-next";
import type {
  EditKanbanCardQuery,
  KanbanCardFieldsFragment,
  KanbanCardInput,
  Project,
  ProjectPickerQuery,
  TaskFieldsFragment,
  TaskInput
} from "~/types/api";
import { useCurrentUser } from "~/utils/auth";
import { useFetcherData } from "~/utils/remix";
import { BLANK_SLATE } from "~/utils/text";
const COLUMNS_V2 = ["Backlog", "Next", "Scheduled", "Today", "Done"];

interface Props extends ModalProps {
  id?: string;
  userId?: string;
  column?: string;
  contentTopicId?: string;
  messageId?: string;
  deliverableId?: string;
  trainingId?: string;
  project?: { id: string; status: string } | null;
  featureId?: string;
  salesBoard?: boolean;
}

interface InnerProps extends Omit<Props, "id"> {
  source?: KanbanCardFieldsFragment;
}

export type KanbanCardInputWithTasks = Omit<KanbanCardInput, "tasks"> & {
  tasks: TaskInput[];
};

export default function KanbanCardForm({ id, ...props }: Props) {
  const fetcher = useFetcherData<EditKanbanCardQuery>(
    id ? `/resources/kanban/${id}` : undefined
  );
  if (id && !fetcher.data) {
    return null;
  }
  return <Form source={id ? fetcher.data?.kanbanCard : undefined} {...props} />;
}

const Form = ({ source, onClose, salesBoard, ...props }: InnerProps) => {
  const fetcher = useFetcher<unknown>();
  const currentUser = useCurrentUser();
  const blockedRef = useRef<HTMLInputElement>(null);
  const [selectingTasks, setSelectingTasks] = useState(false);
  const oldColumn =
    ["Could Do", "Should Do", "Urgent"].includes(source?.column || "") &&
    source?.column;

  const data = source
    ? {
        ...source,
        column: oldColumn ? undefined : source.column,
        projectId: source.project?.id,
        featureId: source.feature?.id,
        trainingId: source.training?.id,
        subFeatureId: source.subFeature?.id,
        deliverableId: source.deliverable?.id,
        messageId: source.message?.id,
        contentTopicId: source.contentTopic?.id,
        userId: source.user?.id
      }
    : {
        deliverableId: props.deliverableId || "",
        projectId: props.project?.id || "",
        featureId: props.featureId || "",
        trainingId: props.trainingId || "",
        contentTopicId: props.contentTopicId || "",
        messageId: props.messageId,
        notesSlate: BLANK_SLATE,
        userId: props.userId || (props.featureId ? "5" : ""),
        column: props.column === "N/A" ? undefined : props.column,
        blocked: false
      };

  const [column, setColumn] = useState(data.column);
  const [userId, setUserId] = useState(data.userId);
  const [project, _setProject] = useState<
    Pick<Project, "id" | "status"> | undefined | null
  >(source ? source.project : props.project);
  const [contentTopicId, setContentTopicId] = useState(data.contentTopicId);
  const [trainingId, _setTrainingId] = useState(data.trainingId);
  const [featureId, _setFeatureId] = useState(data.featureId);
  const [deliverableId, setDeliverableId] = useState(data.deliverableId);
  const [blocked, _setBlocked] = useState(data.blocked);
  const setBlocked = (value: boolean) => {
    _setBlocked(value);
    if (value) {
      setTimeout(() => blockedRef.current?.focus(), 50);
    }
  };
  const setProjectId = (
    value: string,
    p: ProjectPickerQuery["projects"][0] | null
  ) => {
    _setProject(p);
    if (!p || p.id !== project?.id) {
      setDeliverableId("");
    }
    setTasks((tasks) => tasks.map((t) => ({ ...t, projectId: value })));
  };
  const setFeatureId = (value: string) => {
    _setFeatureId(value);
    _setTrainingId("");
    setDeliverableId("");
    setTasks((tasks) => tasks.map((t) => ({ ...t, featureId: value })));
  };
  const setTrainingId = (value: string) => {
    _setTrainingId(value);
    _setFeatureId("");
    setDeliverableId("");
    setTasks((tasks) => tasks.map((t) => ({ ...t, trainingId: value })));
  };
  const [tasks, setTasks] = useState<TaskInput[]>(
    source
      ? _.sortBy(
          source.tasks.map((t) =>
            _.omit(
              {
                ...t,
                uuid: uuidv1(),
                projectId: t.projectId,
                deliverableId: t.deliverableId,
                userId: t.user?.id || ""
              },
              "__typename",
              "user"
            )
          ),
          "itemDate",
          "id"
        )
      : []
  );

  const [mode, setMode] = useState("form");

  const addTask = () =>
    setTasks((tasks) => [
      ...tasks,
      {
        uuid: uuidv1(),
        userId,
        creatorId: currentUser.id,
        projectId: project?.id,
        featureId,
        trainingId
      }
    ]);

  const destroy = () => {
    if (
      window.confirm(
        "Are you sure you want to delete this card (and any associated actions)?"
      )
    ) {
      source &&
        fetcher.submit(
          { id: source.id },
          { action: "/resources/kanban/delete", method: "post" }
        );
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (fetcherFailed(fetcher)) {
      window.alert(
        "This card has already been deleted by someone else.\n\nPlease reload the page to make sure you are using the current kanban board."
      );
    }
  }, [fetcher.state]);

  const toggleMode = () => {
    setMode(mode === "form" ? "metrics" : "form");
  };

  const _mode = project
    ? "Project"
    : data.contentTopicId
      ? "Content Page"
      : featureId
        ? "Feature"
        : trainingId
          ? "Training"
          : "Personal";

  const handleTaskDelete = useCallback((task: TaskInput) => {
    setTasks((tasks) => {
      const index = tasks.findIndex((t) => t.uuid === task.uuid);
      const match = tasks[index];
      if (task.id) {
        return [
          ...tasks.slice(0, index),
          { ...match, delete: true },
          ...tasks.slice(index + 1)
        ];
      }
      return [...tasks.slice(0, index), ...tasks.slice(index + 1)];
    });
  }, []);

  const handleTaskChange = useCallback(
    (task: TaskInput, field: string, value: string) => {
      setTasks((tasks) => {
        const index = tasks.findIndex((t) => t.uuid === task.uuid);
        const match = tasks[index];
        return [
          ...tasks.slice(0, index),
          { ...match, [field]: value },
          ...tasks.slice(index + 1)
        ];
      });
    },
    []
  );

  const handleTaskToggle = useCallback(
    (task: TaskInput) => {
      setTasks((tasks) => {
        const index = tasks.findIndex((t) => t.uuid === task.uuid);
        const match = tasks[index];

        return [
          ...tasks.slice(0, index),
          match.completed
            ? { ...match, completed: false, completerId: "" }
            : { ...match, completed: true, completerId: currentUser.id },
          ...tasks.slice(index + 1)
        ];
      });
    },
    [currentUser.id]
  );

  const handleTaskLink = useCallback((task: TaskFieldsFragment) => {
    setTasks((tasks) => {
      const index = tasks.findIndex((t) => t.id === task.id);
      if (index >= 0) {
        return [...tasks.slice(0, index), ...tasks.slice(index + 1)];
      }
      return [
        ...tasks,
        _.pick(
          {
            ...task,
            uuid: uuidv1(),
            userId: task.user?.id
          },
          "id",
          "description",
          "userId",
          "itemDate",
          "priority",
          "hours",
          "completed",
          "creatorId"
        )
      ];
    });
  }, []);

  const ref = useRef<HTMLInputElement>(null);

  return (
    <Modal onExplicitClose={onClose} size="Large" initialFocus={ref}>
      <RemixForm
        data={data}
        fetcher
        action="/resources/kanban/save"
        onSuccess={onClose}
      >
        <input type="hidden" name="messageId" value={data.messageId} />

        <Modal.Header title={source ? "Edit Card" : "New Card"} />
        {mode === "form" ? (
          <div>
            <Modal.Body>
              {props.messageId && (
                <Alert>This card will be linked to the selected message</Alert>
              )}
              {project?.status === "Closed" && (
                <Alert mode="warning">
                  <p>
                    This project is closed. You can still add this card, but it
                    will only appear on this project's page and not on anyone's
                    individual Kanban Board.
                  </p>
                  <p>
                    If you are adding a collections-related card, please add it
                    to OH-015 Accounts Receivable instead and reference this
                    project in the description.
                  </p>
                </Alert>
              )}
              <Errors />

              {source?.feature || featureId ? (
                <Input
                  name="featureId"
                  type="feature"
                  value={featureId}
                  onChange={(_name, value) => setFeatureId(value)}
                  viewLink
                />
              ) : source?.training || trainingId ? (
                <Input
                  name="trainingId"
                  type="training"
                  value={trainingId}
                  onChange={(name, value) => setTrainingId(value)}
                  viewLink
                />
              ) : (
                <div className="grid grid-cols-2 gap-8">
                  {!contentTopicId && (
                    <Input
                      name="projectId"
                      type="project"
                      wrapperClassName={project ? "col-span-2" : ""}
                      value={project?.id || ""}
                      onChange={(_name, value, p) => {
                        setProjectId(
                          value,
                          p as ProjectPickerQuery["projects"][0]
                        );
                        setContentTopicId("");
                      }}
                      viewLink
                      filter={salesBoard ? "Sales" : undefined}
                    />
                  )}
                  {!project && (
                    <Input
                      name="contentTopicId"
                      type="contentTopic"
                      wrapperClassName={contentTopicId ? "col-span-2" : ""}
                      value={contentTopicId}
                      onChange={(_name, value) => {
                        setContentTopicId(value);
                        setProjectId("", null);
                      }}
                      viewLink
                    />
                  )}
                </div>
              )}
              {featureId && (
                <Input
                  name="subFeatureId"
                  type="feature"
                  label="Sub-Feature"
                  viewLink
                  excludeId={featureId}
                />
              )}
              <div className="grid grid-cols-6 gap-8">
                {(_mode === "Project" || project) && (
                  <div className="col-span-2">
                    <Input
                      name="deliverableId"
                      type="deliverable"
                      value={deliverableId}
                      onChange={(_name, value) => setDeliverableId(value)}
                      projectId={project?.id || undefined}
                    />
                  </div>
                )}
                <div
                  className={_mode === "Project" ? "col-span-2" : "col-span-3"}
                >
                  <Input
                    name="title"
                    label={`${_mode === "Project" ? "and/or " : ""} Title`}
                    forwardRef={ref}
                  />
                </div>
                <div
                  className={_mode === "Project" ? "col-span-2" : "col-span-3"}
                >
                  <Input
                    name="userId"
                    label="Who"
                    value={userId}
                    onChange={(_name, value) => setUserId(value)}
                    placeholder="Who is doing this?"
                    type="user"
                    project={project ? { id: project.id } : undefined}
                  />
                </div>
              </div>
              <div className="grid grid-cols-4 gap-8">
                <div className="col-span-2">
                  <Input
                    name="column"
                    type="button-select"
                    value={column}
                    options={COLUMNS_V2}
                    onChange={(_name, value) => setColumn(value)}
                    helpAfter={
                      oldColumn &&
                      !column && (
                        <Alert mode="danger" className="mt-4">
                          <IconExclamation /> <strong>{oldColumn}</strong> is
                          not available anymore. Please pick a new column.
                        </Alert>
                      )
                    }
                  />
                </div>
                <Input name="dueDate" type="date" label="Due" icon />
                <Input name="hours" />
              </div>
              <div className="grid grid-cols-2 gap-8">
                <Input
                  wrapperClassName="mt-0"
                  name="blocked"
                  type="checkbox"
                  checked={!!blocked}
                  onChange={(e) => setBlocked(e.target.checked)}
                  inline
                  label="Blocked?"
                />
                {blocked && (
                  <Input
                    name="blockedReason"
                    forwardRef={blockedRef}
                    label="Reason it's Blocked"
                  />
                )}
              </div>
              <Input
                name="notesSlate"
                type="slate"
                placeholder="Additional information..."
                noLabel
              />
              {source?.message && (
                <div className="form-group">
                  <label>Associated Message</label>
                  <div>
                    <Link
                      to={
                        source.message.project
                          ? `/projects/${source.message.project.number}/messages/${source.message.id}`
                          : `/sales/inbox/${source.message.id}`
                      }
                    >
                      {source.message.subject || "Untitled Message"}
                    </Link>
                  </div>
                </div>
              )}
              <Input
                type="upload"
                name="uploadIds"
                label="Attached Files"
                multiple
              />
            </Modal.Body>
            <Heading small title="Actions/Tasks" className="px-6">
              {selectingTasks ? (
                <Button
                  mode="success"
                  small
                  onClick={() => setSelectingTasks(false)}
                >
                  <IconCheck /> Done
                </Button>
              ) : (
                <ButtonGroup>
                  <Button
                    mode="success"
                    small
                    onClick={() => setSelectingTasks(true)}
                  >
                    <IconList /> Link/Unlink Actions
                  </Button>
                  <Button mode="success" small onClick={addTask}>
                    <IconAdd /> Add Action
                  </Button>
                </ButtonGroup>
              )}
            </Heading>
            <input type="hidden" name="tasks" value={JSON.stringify(tasks)} />
            {selectingTasks ? (
              <LinkTasks
                selectedTasks={tasks}
                id={source?.id}
                projectId={project?.id}
                featureId={featureId}
                onLink={handleTaskLink}
              />
            ) : (
              <InlineTaskFieldsNext
                tasks={tasks}
                onChange={handleTaskChange}
                onDelete={handleTaskDelete}
                onToggle={handleTaskToggle}
              />
            )}
          </div>
        ) : (
          <Metrics card={source!} />
        )}
        <Modal.Footer>
          <Buttons
            modal
            closeOnly={mode === "metrics"}
            closeLabel="Cancel"
            rightContent={
              source && (
                <Button onClick={toggleMode}>
                  {mode === "form" ? "View Metrics" : "Edit Card"}
                </Button>
              )
            }
          >
            {source && (
              <div className="flex items-center space-x-4">
                <Button mode="danger" onClick={destroy}>
                  <IconTrash /> Delete Card
                </Button>
                <em className="text-gray-500">
                  Added <FormattedDate date={source.createdAt} format="L LT" />{" "}
                  by {source.creator?.fullname || "N/A"}
                </em>
              </div>
            )}
          </Buttons>
        </Modal.Footer>
      </RemixForm>
    </Modal>
  );
};
